<template>
	<div class="popup active">
		<div class="notice_popup select_corp" style="margin: 200px auto;">
            <div class="close">
                <img src="/images/close.png" alt="닫기" @click="$emit('close', {chkFlag : false})"/>
            </div>
			<div class="tit" @click="getScoCorpList()">공급기업 선택</div>

            <div class="corp_info_box">
                <div class="inner left" id="scoList-scroll-area">
                    <div class="search-sco-btn">
                        <input type="text" placeholder="공급기업 검색" v-model="searchKeyword" @keyup.enter="getScoCorpList('1')">
						<img src="/images/icon_srch.png" @click="getScoCorpList('1')">
                    </div>
                    
                    <div class="list">
                        <table id="scoList-inner-area">
                            <colgroup>
                                <col width="60%">
                                <col width="40%">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>공급기업명</th>
                                    <th>사업자등록번호</th>
                                </tr>
                            </thead>
                            <tbody v-if="scoCorpList.length > 0">
                                <tr class="select-hover" v-for="(scoInfo, index) in scoCorpList" :key="index"  @click="addSco(scoInfo)">
                                    <td><div class="corpNm">{{scoInfo.corpNm}}</div></td>
                                    <td class="hover-img">{{ scoInfo.bizRegNo | bizNo }}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td class="searh-none-corp" colspan="2"><div>검색된 공급기업이 없습니다!</div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="inner right">
                    <div class="search-sco-btn">
                        <div>선택한 공급기업</div>
                    </div>
                    <div class="list">
                        <table v-if="tempScoFieldList.length > 0">
                            <colgroup>
                                <col width="10%">
                                <col width="50%">
                                <col width="35%">
                                <col width="5%">
                            </colgroup>
                            <tbody>
                                <tr v-for="(scoInfo, index) in tempScoFieldList" :key="index">
                                    <td class="td-idx">{{ index+1 }}</td>
                                    <td><div class="corpNm">{{scoInfo.corpNm}}</div></td>
                                    <td class="hover-img">{{ scoInfo.bizRegNo | bizNo }}</td>
                                    <td><button class="btn-delete" @click="delSco(index)">삭제</button></td>
                                </tr>
                            </tbody>
                        </table>

                        <!-- 선택한 공급기업이 없을때 -->
                        <div v-else class="selected-none-box">
                            <span class="selected-none-corp">&lt; 공급기업을 선택해주세요!</span>
                        </div>

                    </div>
                </div>
            </div>
           
            <div class="select-btn">
                <div class="btn" @click="$emit('close', {chkFlag : false})">취소</div>
                <div class="btn" @click="saveSelectedScoCorp()">확인</div>
            </div>			
        </div>
	</div>
</template>

<script>

export default {
    props : {param : Object},
	
    data() { 
        return { 
            scoCorpList : [],
            tempScoFieldList : [],

            nextKey : '',
            searchKeyword: '',
            
            scrollArea : ''
        }; 
    },
    beforeMount() {
        this.getScoCorpList();
        this.tempScoFieldList = this.param.scoFieldList.slice();
    },

    mounted() {
        this.scrollArea =document.getElementById('scoList-scroll-area');
        this.scrollArea.addEventListener('scroll', this.scrollEvents, true);
    },

    methods: {
        scrollEvents() {
            var scrollArea = this.scrollArea.getBoundingClientRect().bottom;
            var innerArea = document.getElementById('scoList-inner-area').getBoundingClientRect().bottom;

            if(innerArea == (scrollArea + 1)){
                this.getScoCorpList();
            }   
        },

        getScoCorpList(div) {
            if(div) {
                this.nextKey = '';
                this.scoCorpList = [];
            }

            if(this.nextKey === undefined) return;

			this.$.httpPost('/api/prj/sco/choice/getScoCorpList', {nextKey : this.nextKey, searchKeyword: this.searchKeyword})
                .then(res => {
                    this.scoCorpList = this.scoCorpList.concat(res.data.scoCorpList);
                    this.nextKey = res.data.nextKey;

                }).catch(this.$.httpErrorCommon);
		},

        addSco(sco) {
            for(var i=0; i < this.tempScoFieldList.length; i++){
                if(this.tempScoFieldList[i].bizRegNo == sco.bizRegNo){
                    alert('이미 선택한 공급기업입니다!');
                    return;
                }
            }

            this.tempScoFieldList.push(sco);		
		},

        delSco(index) {
			this.tempScoFieldList.splice(index,1);
			// this.scoList[list.index].checked=false;
		},

        saveSelectedScoCorp(){
            this.param.scoFieldList = this.tempScoFieldList;
            this.$emit('close', {chkFlag : true, tempScoFieldList : this.tempScoFieldList});
        },

	},
};
</script>
